//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

.panel-body {
  padding: 0.25rem !important;
}

.qc-column {
  margin: 0 10px 0 10px;
  border: 4px solid #2569c3;
}

.qc-hide-right-column {
  border-top: 4px solid #2569c3;
  border-left: 4px solid #2569c3;
  border-bottom: 4px solid #2569c3;
  margin: 0 0 0 10px;
}

.qc-hide-left-column {
  border-top: 4px solid #2569c3;
  border-right: 4px solid #2569c3;
  border-bottom: 4px solid #2569c3;
  margin: 0 10px 0 0;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.alert-modal-body {
  font-size: 25px;
  padding: 3rem !important;
}

.shadow-element-red {
  animation: shadow-animation-red 1s ease-out infinite alternate;
  outline: none;
}

@keyframes shadow-animation-red {
  0% {
    box-shadow: 0 0 0 0 red;
  }

  25% {
    box-shadow: 25px 25px 35px 5px red;
  }

  50% {
    box-shadow: -25px 25px 35px 5px red;
  }

  75% {
    box-shadow: -25px -25px 35px 5px red;
  }

  100% {
    box-shadow: 25px -25px 35px 5px red;
  }
}

.shadow-element-yellow {
  animation: shadow-animation-yellow 1s ease-out infinite alternate;
}

@keyframes shadow-animation-yellow {
  0% {
    box-shadow: 0 0 0 0 yellow;
  }

  25% {
    box-shadow: 25px 25px 35px 5px yellow;
  }

  50% {
    box-shadow: -25px 25px 35px 5px yellow;
  }

  75% {
    box-shadow: -25px -25px 35px 5px yellow;
  }

  100% {
    box-shadow: 25px -25px 35px 5px yellow;
  }
}

.qcModal {
  width: 95vw !important;
  /* Occupy the 90% of the screen width */
  max-width: 96vw !important;
  height: 70vh !important;
  max-height: 70vh !important;
}

.qcListGroup {
  overflow-y: auto;
  max-height: 35vh;
}


.qcCaretIcon {
  margin-left: 7px;
}

.passed {
  color: green;
}

.frame-detail-v2-table-1 td {
  padding-top: 1px !important;
  padding-bottom: 10px !important;
}

.frame-detail-v2-table-2 td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.check-img {
  width: 200px;
}

.frame-detail-body {
  display: flex;
  gap: 10px;
  font-size: 18px;

  &__text-property {
      width: 200px;
      font-weight: bold;
      text-align: right;
    }
    
  &__text-value {
    font-weight: 500;
  }
}


@media only screen and (max-width: 975px) {
  .check-img {
    width: 150px;
  }
}

.job-no-title {
  font-size: 20px !important;
}

.user-logged-name {
  font-size: 18px;
}

.live-time-clock {
  width: 220px;
}

.footer-text {
  font-size: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .user-logged-name {
    font-size: 14px;
  }

  .live-time-clock {
    width: 170px;
    font-size: 14px;
  }

  .job-no-title {
    font-size: 15px !important;
  }

  .frame-detail-v2-table-1 td,
  .frame-detail-v2-table-2 td {
    font-size: 10px !important;
  }

  .footer-text {
    font-size: 14px;
  }

  .qcListGroup {
    overflow-y: auto;
    max-height: 25vh;
  }
}
